@import 'css/shared';

.top-bar {
  .top-bar-swiper {
    $swiper-navitation-width: 30px; // Larghezza delle frecce di navigazione

    button {
      height: 100%;
      width: $swiper-navitation-width;
    }

    .swiper {
      padding: 0 $swiper-navitation-width;
    }
  }
}

.header-checkout {
  text-transform: uppercase;

  .header-checkout-desktop {
    .copy-container {
      font-size: $h4-font-size;
      font-weight: 500;
    }
  }

  button {
    padding: map-get($spacers, 2);
    font-weight: 400;
    color: $dark;
    background: transparent;
    border: 0;

    .Icon {
      display: none;
    }

    &:hover {
      background: transparent;
      color: $dark;
    }
  }

  .header-checkout-mobile-first-line {
    padding: map-get($spacers, 3) 0 !important;
  }

  .header-checkout-mobile-second-line {
    background: $nude-100;
    padding-bottom: 0 !important;

    .copy-container {
      font-weight: 700;
    }

    .copy-container,
    .Button {
      font-size: $extra-small-font-size;
    }
  }

  @include media-breakpoint-down(lg) {
  }
}

.desktop-header-first-row,
.desktop-header-second-row,
.mobile-header {
  .badge {
    width: 18px;
    height: 18px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-80%, -35%) !important;
    font-size: 10px;
  }
}
