@import 'css/shared';

.account-offcanvas {
  .offcanvas-body-registration-wrapper {
    background-color: $nude !important;
  }

  .offcanvas-body {
    .btn-primary {
      width: 50%;
    }
  }
  .offcanvas-body-title {
    font-size: $h3-font-size;
    text-transform: uppercase;
    font-weight: 500;
  }
  .icon-box {
    margin-right: spacer(2);
    .icon {
      display: inline-block;
      background-color: $white-nude-100;
      border-radius: 50%;
      width: 45px;
      height: 45px;
      line-height: 45px;
      text-align: center;
      text-align: center;
    }
    .label {
      line-height: 1.2;
      font-size: $extra-small-font-size;
    }
  
  }
}
