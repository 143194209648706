@import '../css/shared';

.icons-d {
  @include media-breakpoint-up(lg) {
  }

  @include media-breakpoint-down(lg) {
  }

  .icon-column {
    .title {
      font-size: $h3-font-size;
      line-height: $headings-line-height;
    }

    .subTitle {
      font-size: $font-size-sm;
    }
  }

  .swiper-pagination {
    margin: map-get($spacers, 3) 0;
  }
}
