@import '../css/shared';

.form-group {
  .form-label.required:after {
    content: '*';
    margin-left: spacer(1);
    color: $red;
  }
}

.customer-form-b {
  @include media-breakpoint-down(lg) {
    .btn {
      width: 100%;
    }
  }
  .box-newsletter-radio {
    background-color: $white-nude;
    text-align: center;
    padding: spacer(3);
    .title {
      font-weight: 700;
      font-size: $h4-font-size;
    }
    .radio {
      font-size: $small-font-size;
    }
  }
}

.LoginForm {
  .forgot-pass {
    font-weight: 500 !important;
    color: $primary !important;
  }
}

.PageWithSubpageHeader {
  .title {
    font-size: $h3-font-size;
    text-transform: uppercase;
    font-weight: 500 !important;
  }
  .subpage-title {
    font-size: $h5-font-size;
    text-transform: uppercase;
    font-weight: 500 !important;
  }
}

.pagination {
  .page-item {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    &.active {
      .page-link {
        font-weight: 700;
        border-bottom: 3px solid $primary;
      }
    }
  }
}

.Breadcrumb {
  ol {
    margin: 0;
  }

  .breadcrumb-item {
    text-transform: capitalize;

    a {
      text-decoration: none;
    }

    & + .breadcrumb-item:before {
      content: '';
      float: none;
      padding: 0;
      display: inline-block;
      width: 6px;
      height: 6px;
      top: -1px;
      position: relative;
      margin-right: 11px !important;
      border-right: 1px solid $dark;
      border-bottom: 1px solid $dark;
      transform: rotate(-45deg);
    }

    &.active {
      font-weight: $gray-500;
    }
  }
}

.top-suggestions {
  .title {
    text-transform: uppercase;
  }
  .product {
    .name {
      font-size: $small-font-size;
      font-weight: 700;
    }
    .price {
      font-weight: 700;
    }
    .crossed-out-price {
      font-weight: 400;
    }
  }
}

.swiper-pagination {
  position: relative !important;
  bottom: 0 !important;
  display: flex;
  justify-content: center;
  gap: map-get($spacers, 2);

  .swiper-pagination-bullet {
    border-radius: 0;
    margin: 0 !important;
    width: 11px;
    height: 11px;
    background-color: $dark;
  }
}
