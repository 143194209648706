@import 'css/shared';

.form-group {
  .custom-radio {
    cursor: pointer;
    border: 1px solid transparent;
    font-size: $small-font-size;

    &:not(.with-icon) {
      padding: 0 map-get($spacers, 2);
    }

    &.with-icon {
      border-radius: 50%;
      overflow: hidden;
      width: 30px;
      height: 30px;

      img {
        border-radius: 50%;
        border: 0 solid transparent;
        overflow: hidden;
        transition: all 0.2s;
      }
    }
    &.with-image {
      width: 70px;
    }
  }

  input + label {
    .custom-radio:not(.with-icon) {
      border-color: $nude-200;
    }
  }

  input:checked + label {
    .custom-radio {
      border-color: $dark;

      &:not(.with-icon) {
        border-color: $nude-200;
      }

      &:not(.with-image) img {
        border: 2px solid $white;
      }
      &.with-image {
        border: 3px solid $nude-200;
      }
    }

    .custom-radio:not(.with-icon):not(.with-image) {
      background-color: $nude-200;
    }
  }
}
