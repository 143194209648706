@import '~bootstrap/scss/functions';
@import 'variables';

// RIDEFINISCO ALCUNE COSE SENZA MODIFICARLE, GIUSTO PER AVERLE DIPONIBILI >>>>>

$custom-container-max-widths: (
  xxl: 1434px,
);

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;

// BOOTSTRAP OVERRIDES BEFORE IMPORT >>>>>

$enable-negative-margins: true;

// Typography

// $font-family-sans-serif: 'Helvetica Neue', sans-serif;
$font-family-sans-serif: 'Neue Haas Grotesk Text', sans-serif;
$headings-font-family: 'Neue Haas Grotesk Text', sans-serif;
$display-font-family: 'Neue Haas Grotesk Display', sans-serif;

$font-size-base: 1rem;

$small-font-size: $font-size-base * 0.875;
$extra-small-font-size: $font-size-base * 0.75;

$display-font-sizes: (
  1: $font-size-base * 3.75,
  2: $font-size-base * 2.625,
);

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.625;
$h3-font-size: $font-size-base * 1.375;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1.125;

$headings-font-weight: 400;
$headings-line-height: 1.2;

// Colors

$black: #000000;
$white: #ffffff;

$dark: #1a1718;
$light: #fafafa;

$gray-100: #f6f6f6;
$gray-200: #e6e6e6;
$gray-300: #d7d7d7;
$gray-400: #b4b4b4;
$gray-500: #989898;

$nude: #e8cfc7;
$nude-100: #f1e5e1;
$nude-200: #efdfdb;
$nude-300: #f7e5df;
$white-nude: #f9f3f1;
$white-nude-100: #fff5ef;

$mint: #e2f4ec;

$red: #ca1c00;
$orange: #c91c00;
$crab-apple: #8c3b2e;

$custom-colors: (
  'nude': $nude,
  'nude-100': $nude-100,
  'nude-200': $nude-200,
  'nude-300': $nude-300,
  'white-nude': $white-nude,
  'white-nude-100': $white-nude,
  'mint': $mint,
  'crab-apple': $crab-apple,
  'orange': $orange,
  'red': $red,
  'gray-100': $gray-100,
  'gray-200': $gray-200,
  'gray-300': $gray-300,
  'gray-400': $gray-400,
  'gray-500': $gray-500,
  // 'offer': $nude,
);

$body-bg: $white;
$body-color: $dark;

$primary: $dark;
$border-color: $gray-200;
$table-group-separator-color: $border-color;
$table-bg-scale: -10%;
$table-variants: (
  'light': shift-color($nude, $table-bg-scale),
);

$input-border-color: $gray-300;
$input-placeholder-color: $gray-300;

// Offcanvas
$offcanvas-horizontal-width: 500px;
$offcanvas-padding-x: 3rem;
$offcanvas-border-width: 0;

// Modal
$modal-header-border-width: 0;
$modal-footer-border-width: 0;

$breadcrumb-font-size: $extra-small-font-size;

// Form element

// Btn

$input-btn-line-height: 1;

$btn-font-size: $small-font-size;
$btn-font-weight: 700;

$btn-padding-x: 1.5rem;
$btn-padding-y: 0.75rem;

// $btn-disabled-opacity: 1;

$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;

// Alert
$alert-border-radius: 0;

// Label
$form-label-font-size: $small-font-size;

// Input
$input-padding-x: 0.625rem;
$input-padding-x-sm: $input-padding-x;
$input-padding-x-lg: $input-padding-x;
$input-padding-y: 0.625rem;

$input-btn-focus-width: 2px;
$input-btn-focus-color: $nude;

$form-select-padding-y: 0.75rem;

$input-border-radius: 0;
$input-border-radius-sm: 0;
$input-border-radius-lg: 0;
$input-border-color: $dark;
$input-placeholder-color: $gray-500;

$input-disabled-bg: $gray-200;
$input-disabled-border-color: $gray-500;

// Feedback

$form-feedback-font-size: $extra-small-font-size;

// Select

$form-select-border-radius: $input-border-radius;

// Accordion
$accordion-border-color: $primary;
$accordion-border-radius: 0;
$accordion-icon-color: $primary;
$accordion-icon-width: 1.5rem;
$accordion-button-focus-box-shadow: none;
$accordion-button-active-bg: $white;
$accordion-button-active-color: $black;

// Checkbox - Radios

$form-check-input-border: 1px solid $input-border-color;
$form-check-input-border-radius: 0;
$form-check-input-checked-color: $light;
$form-check-input-checked-bg-color: $dark;
$form-check-input-checked-border-color: $dark;

$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 0 0'></svg>");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 0 0'></svg>");

// Card

$card-bg: $white;
$card-border-width: 0;
$card-border-radius: 0;

// Pagination
$pagination-color: $gray-500;
$pagination-bg: transparent;
$pagination-disabled-bg: transparent;
$pagination-focus-bg: transparent;
$pagination-focus-color: $primary;
$pagination-hover-bg: transparent;
$pagination-active-color: $primary;
$pagination-active-bg: transparent;
$pagination-border-radius: 0;
$pagination-border-width: 0;
$pagination-padding-y: 0.5rem;
$pagination-padding-x: 0.5rem;
$pagination-padding-y-sm: 0.5rem;
$pagination-padding-x-sm: 0.5rem;
$pagination-padding-y-lg: 0.5rem;
$pagination-padding-x-lg: 0.5rem;

// BOOTSTRAP OVERRIDES BEFORE IMPORT <<<<<

@import '../../node_modules/bootstrap/scss/variables';
//@import '/node_modules/bootstrap/scss/utilities';

$container-max-widths: map-merge($container-max-widths, $custom-container-max-widths);
$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');
$utilities-text: map-merge($utilities-text, $custom-colors);
$utilities-text-colors: map-loop($utilities-text, rgba-css-var, '$key', 'text');
$utilities-bg: map-merge($utilities-bg, $custom-colors);
$utilities-bg-colors: map-loop($utilities-bg, rgba-css-var, '$key', 'bg');
