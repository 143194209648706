@import '../css/shared';

.checkout-page {

  .step-title {
    font-weight: 700;
  }

  .step-number {
    font-size: $h5-font-size;
    font-weight: 700;
  }

  .btn-blank {
    text-transform: none !important;
    font-weight: 400 !important;
  }

  .btn-link {
    text-transform: none !important;
    font-weight: 700 !important;
    text-decoration: underline !important;
  }

  // .checkout-step {

  //   .card {
      
  //     &.border-bottom {
  //       border-color: $dark !important;
  //     }

  //   }

  // }

  .checkout-summary {

    h5 {
      font-size: $h3-font-size;
      font-weight: 700;
    }

    .border-bottom {
      border-color: $dark !important;
    }
    
  }

  @include media-breakpoint-down(lg) {}

  @include media-breakpoint-up(lg) {}

}