@import 'css/shared';

.product-page {
  .price-block {
    @include media-breakpoint-up(sm) {
    }
    @include media-breakpoint-down(sm) {
    }
  }
}

.product-sticky-footer {
  .price-block {
    @include media-breakpoint-up(sm) {
    }
    @include media-breakpoint-down(sm) {
    }
  }

  .discount-percentage {
    background-color: $dark;
    color: $light;
    padding: 0 map-get($spacers, 2);
    font-weight: 500;
    font-size: $small-font-size;
    display: flex;
    align-items: center;
    @include media-breakpoint-up(sm) {
    }
    @include media-breakpoint-down(sm) {
    }
  }

  .product-sticky-footer-mobile {
    @include media-breakpoint-up(sm) {
    }
    @include media-breakpoint-down(sm) {
    }
  }
}

.product-tile {
  .price-block {
    font-size: $small-font-size;

    .d-flex {
      gap: 0.5rem;
    }

    @include media-breakpoint-up(sm) {
    }
    @include media-breakpoint-down(sm) {
    }
  }
}
