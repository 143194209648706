@import '../css/shared';

.icons-a {
  text-wrap: balance;

  .title {
    text-transform: uppercase;
    font-weight: 500;
    line-height: $headings-line-height;

    @include media-breakpoint-up(lg) {
      font-size: $h1-font-size;
      margin-bottom: map-get($spacers, 4);
    }

    @include media-breakpoint-down(lg) {
      font-size: $h3-font-size;
      margin-bottom: map-get($spacers, 3);
    }
  }

  .content {
    @include media-breakpoint-up(lg) {
      font-size: $h4-font-size;
      line-height: $headings-line-height;
      font-weight: 500;
    }
    @include media-breakpoint-down(lg) {
    }
  }

  .icon-text {
    text-transform: uppercase;
    font-weight: 500;
    @include media-breakpoint-up(lg) {
      font-size: $h4-font-size;
      line-height: $headings-line-height;
    }
    @include media-breakpoint-down(lg) {
      font-size: $small-font-size;
    }
  }
}
