/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * neue-haas-grotesk-display:
 *   - http://typekit.com/eulas/00000000000000007735bb34
 *   - http://typekit.com/eulas/00000000000000007735bb36
 *   - http://typekit.com/eulas/00000000000000007735bb38
 *   - http://typekit.com/eulas/00000000000000007735bb4c
 *   - http://typekit.com/eulas/00000000000000007735bb55
 *   - http://typekit.com/eulas/00000000000000007735bb42
 *   - http://typekit.com/eulas/00000000000000007735bb48
 *   - http://typekit.com/eulas/00000000000000007735bb53
 *   - http://typekit.com/eulas/00000000000000007735bb5a
 *   - http://typekit.com/eulas/00000000000000007735bb5e
 *   - http://typekit.com/eulas/00000000000000007735bb62
 *   - http://typekit.com/eulas/00000000000000007735bb67
 *   - http://typekit.com/eulas/00000000000000007735bb6a
 *   - http://typekit.com/eulas/00000000000000007735bb6c
 *   - http://typekit.com/eulas/00000000000000007735bb6e
 *   - http://typekit.com/eulas/00000000000000007735bb70
 * neue-haas-grotesk-text:
 *   - http://typekit.com/eulas/00000000000000007735bb33
 *   - http://typekit.com/eulas/00000000000000007735bb35
 *   - http://typekit.com/eulas/00000000000000007735bb32
 *   - http://typekit.com/eulas/00000000000000007735bb4b
 *   - http://typekit.com/eulas/00000000000000007735bb39
 *   - http://typekit.com/eulas/00000000000000007735bb3d
 *
 * © 2009-2024 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2024-11-21 13:37:40 UTC"}*/

@import url('https://p.typekit.net/p.css?s=1&k=hvt3zbs&ht=tk&f=39488.39489.39490.39491.39492.39493.39494.39495.39496.39497.39498.39499.39500.39501.39502.39503.39504.39505.39506.39507.39508.39509&a=104265982&app=typekit&e=css');

// @font-face {
//   font-family: 'Neue Haas Grotesk Display';
//   src: url('https://use.typekit.net/af/341e74/00000000000000007735bb34/30/l?subset_id=2&fvd=n1&v=3') format('woff2'),
//     url('https://use.typekit.net/af/341e74/00000000000000007735bb34/30/d?subset_id=2&fvd=n1&v=3') format('woff'),
//     url('https://use.typekit.net/af/341e74/00000000000000007735bb34/30/a?subset_id=2&fvd=n1&v=3') format('opentype');
//   font-display: auto;
//   font-style: normal;
//   font-weight: 100;
//   font-stretch: normal;
// }

// @font-face {
//   font-family: 'Neue Haas Grotesk Display';
//   src: url('https://use.typekit.net/af/c91b88/00000000000000007735bb36/30/l?subset_id=2&fvd=i1&v=3') format('woff2'),
//     url('https://use.typekit.net/af/c91b88/00000000000000007735bb36/30/d?subset_id=2&fvd=i1&v=3') format('woff'),
//     url('https://use.typekit.net/af/c91b88/00000000000000007735bb36/30/a?subset_id=2&fvd=i1&v=3') format('opentype');
//   font-display: auto;
//   font-style: italic;
//   font-weight: 100;
//   font-stretch: normal;
// }

// @font-face {
//   font-family: 'Neue Haas Grotesk Display';
//   src: url('https://use.typekit.net/af/99b799/00000000000000007735bb38/30/l?subset_id=2&fvd=n2&v=3') format('woff2'),
//     url('https://use.typekit.net/af/99b799/00000000000000007735bb38/30/d?subset_id=2&fvd=n2&v=3') format('woff'),
//     url('https://use.typekit.net/af/99b799/00000000000000007735bb38/30/a?subset_id=2&fvd=n2&v=3') format('opentype');
//   font-display: auto;
//   font-style: normal;
//   font-weight: 200;
//   font-stretch: normal;
// }

// @font-face {
//   font-family: 'Neue Haas Grotesk Display';
//   src: url('https://use.typekit.net/af/a458e7/00000000000000007735bb4c/30/l?subset_id=2&fvd=i2&v=3') format('woff2'),
//     url('https://use.typekit.net/af/a458e7/00000000000000007735bb4c/30/d?subset_id=2&fvd=i2&v=3') format('woff'),
//     url('https://use.typekit.net/af/a458e7/00000000000000007735bb4c/30/a?subset_id=2&fvd=i2&v=3') format('opentype');
//   font-display: auto;
//   font-style: italic;
//   font-weight: 200;
//   font-stretch: normal;
// }

// @font-face {
//   font-family: 'Neue Haas Grotesk Display';
//   src: url('https://use.typekit.net/af/144886/00000000000000007735bb55/30/l?subset_id=2&fvd=n3&v=3') format('woff2'),
//     url('https://use.typekit.net/af/144886/00000000000000007735bb55/30/d?subset_id=2&fvd=n3&v=3') format('woff'),
//     url('https://use.typekit.net/af/144886/00000000000000007735bb55/30/a?subset_id=2&fvd=n3&v=3') format('opentype');
//   font-display: auto;
//   font-style: normal;
//   font-weight: 300;
//   font-stretch: normal;
// }

// @font-face {
//   font-family: 'Neue Haas Grotesk Display';
//   src: url('https://use.typekit.net/af/3a42f6/00000000000000007735bb42/30/l?subset_id=2&fvd=i3&v=3') format('woff2'),
//     url('https://use.typekit.net/af/3a42f6/00000000000000007735bb42/30/d?subset_id=2&fvd=i3&v=3') format('woff'),
//     url('https://use.typekit.net/af/3a42f6/00000000000000007735bb42/30/a?subset_id=2&fvd=i3&v=3') format('opentype');
//   font-display: auto;
//   font-style: italic;
//   font-weight: 300;
//   font-stretch: normal;
// }

@font-face {
  font-family: 'Neue Haas Grotesk Display';
  src: url('https://use.typekit.net/af/2807c7/00000000000000007735bb48/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/2807c7/00000000000000007735bb48/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/2807c7/00000000000000007735bb48/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display';
  src: url('https://use.typekit.net/af/2b59e1/00000000000000007735bb53/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/2b59e1/00000000000000007735bb53/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/2b59e1/00000000000000007735bb53/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display';
  src: url('https://use.typekit.net/af/1ba16c/00000000000000007735bb5a/30/l?subset_id=2&fvd=n5&v=3') format('woff2'),
    url('https://use.typekit.net/af/1ba16c/00000000000000007735bb5a/30/d?subset_id=2&fvd=n5&v=3') format('woff'),
    url('https://use.typekit.net/af/1ba16c/00000000000000007735bb5a/30/a?subset_id=2&fvd=n5&v=3') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display';
  src: url('https://use.typekit.net/af/6c4da4/00000000000000007735bb5e/30/l?subset_id=2&fvd=i5&v=3') format('woff2'),
    url('https://use.typekit.net/af/6c4da4/00000000000000007735bb5e/30/d?subset_id=2&fvd=i5&v=3') format('woff'),
    url('https://use.typekit.net/af/6c4da4/00000000000000007735bb5e/30/a?subset_id=2&fvd=i5&v=3') format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 500;
  font-stretch: normal;
}

// @font-face {
//   font-family: 'Neue Haas Grotesk Display';
//   src: url('https://use.typekit.net/af/153042/00000000000000007735bb62/30/l?subset_id=2&fvd=n6&v=3') format('woff2'),
//     url('https://use.typekit.net/af/153042/00000000000000007735bb62/30/d?subset_id=2&fvd=n6&v=3') format('woff'),
//     url('https://use.typekit.net/af/153042/00000000000000007735bb62/30/a?subset_id=2&fvd=n6&v=3') format('opentype');
//   font-display: auto;
//   font-style: normal;
//   font-weight: 600;
//   font-stretch: normal;
// }

// @font-face {
//   font-family: 'Neue Haas Grotesk Display';
//   src: url('https://use.typekit.net/af/8a7571/00000000000000007735bb67/30/l?subset_id=2&fvd=i6&v=3') format('woff2'),
//     url('https://use.typekit.net/af/8a7571/00000000000000007735bb67/30/d?subset_id=2&fvd=i6&v=3') format('woff'),
//     url('https://use.typekit.net/af/8a7571/00000000000000007735bb67/30/a?subset_id=2&fvd=i6&v=3') format('opentype');
//   font-display: auto;
//   font-style: italic;
//   font-weight: 600;
//   font-stretch: normal;
// }

@font-face {
  font-family: 'Neue Haas Grotesk Display';
  src: url('https://use.typekit.net/af/384d9b/00000000000000007735bb6a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/384d9b/00000000000000007735bb6a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/384d9b/00000000000000007735bb6a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display';
  src: url('https://use.typekit.net/af/fcc1c9/00000000000000007735bb6c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/fcc1c9/00000000000000007735bb6c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/fcc1c9/00000000000000007735bb6c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

// @font-face {
//   font-family: 'Neue Haas Grotesk Display';
//   src: url('https://use.typekit.net/af/c906c2/00000000000000007735bb6e/30/l?subset_id=2&fvd=n9&v=3') format('woff2'),
//     url('https://use.typekit.net/af/c906c2/00000000000000007735bb6e/30/d?subset_id=2&fvd=n9&v=3') format('woff'),
//     url('https://use.typekit.net/af/c906c2/00000000000000007735bb6e/30/a?subset_id=2&fvd=n9&v=3') format('opentype');
//   font-display: auto;
//   font-style: normal;
//   font-weight: 900;
//   font-stretch: normal;
// }

// @font-face {
//   font-family: 'Neue Haas Grotesk Display';
//   src: url('https://use.typekit.net/af/31808c/00000000000000007735bb70/30/l?subset_id=2&fvd=i9&v=3') format('woff2'),
//     url('https://use.typekit.net/af/31808c/00000000000000007735bb70/30/d?subset_id=2&fvd=i9&v=3') format('woff'),
//     url('https://use.typekit.net/af/31808c/00000000000000007735bb70/30/a?subset_id=2&fvd=i9&v=3') format('opentype');
//   font-display: auto;
//   font-style: italic;
//   font-weight: 900;
//   font-stretch: normal;
// }

@font-face {
  font-family: 'Neue Haas Grotesk Text';
  src: url('https://use.typekit.net/af/0230dd/00000000000000007735bb33/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/0230dd/00000000000000007735bb33/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/0230dd/00000000000000007735bb33/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Text';
  src: url('https://use.typekit.net/af/aed66e/00000000000000007735bb35/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/aed66e/00000000000000007735bb35/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/aed66e/00000000000000007735bb35/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Text';
  src: url('https://use.typekit.net/af/160664/00000000000000007735bb32/30/l?subset_id=2&fvd=n5&v=3') format('woff2'),
    url('https://use.typekit.net/af/160664/00000000000000007735bb32/30/d?subset_id=2&fvd=n5&v=3') format('woff'),
    url('https://use.typekit.net/af/160664/00000000000000007735bb32/30/a?subset_id=2&fvd=n5&v=3') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Text';
  src: url('https://use.typekit.net/af/fe63ce/00000000000000007735bb4b/30/l?subset_id=2&fvd=i5&v=3') format('woff2'),
    url('https://use.typekit.net/af/fe63ce/00000000000000007735bb4b/30/d?subset_id=2&fvd=i5&v=3') format('woff'),
    url('https://use.typekit.net/af/fe63ce/00000000000000007735bb4b/30/a?subset_id=2&fvd=i5&v=3') format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Text';
  src: url('https://use.typekit.net/af/305037/00000000000000007735bb39/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/305037/00000000000000007735bb39/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/305037/00000000000000007735bb39/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Text';
  src: url('https://use.typekit.net/af/68a7c6/00000000000000007735bb3d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/68a7c6/00000000000000007735bb3d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/68a7c6/00000000000000007735bb3d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

.display-1,
.display-2,
.display-3,
.display-4,
.display-5,
.display-6 {
  font-family: $display-font-family;
}

.fw-medium {
  font-weight: 500;
}

.extra-small {
  font-size: $extra-small-font-size;
}

.text-initial {
  text-transform: initial !important;
}
