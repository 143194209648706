@import '../css/shared';

.magazine-a {
  .component-head {
    text-align: center;

    .title {
      margin-bottom: map-get($spacers, 4);
      text-transform: uppercase;
      font-weight: 500;
      line-height: $headings-line-height;
      @include media-breakpoint-up(lg) {
        font-size: $h1-font-size;
      }
      @include media-breakpoint-down(lg) {
        font-size: $h3-font-size;
      }
    }

    .content {
      @include media-breakpoint-up(lg) {
        font-size: $h4-font-size;
      }
      @include media-breakpoint-down(lg) {
      }
    }
  }

  .swiper-container {
    position: relative;

    .swiper-arrow {
      position: absolute;
      z-index: 100;
      top: 50%;

      &.swiper-arrow-left {
        left: 0;
      }

      &.swiper-arrow-right {
        right: 0;
      }
    }
  }

  .swiper-pagination {
    margin: map-get($spacers, 3) 0;
  }
}
