@import '../css/shared';

.double-columns-a {
  text-wrap: pretty;

  @include media-breakpoint-up(lg) {
    .video-portrait {
      .fixed-aspect-ratio-box {
        position: relative !important;
        height: auto !important;
        padding: 0 !important;

        & > div {
          position: relative !important;
          justify-content: center;

          & > div {
            width: auto !important;
            height: auto !important;

            video {
              max-height: 80vh;
              width: auto !important;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    [class^='container'],
    [class^='container'] .row {
      --bs-gutter-x: 0rem;
    }
  }

  .component-head {
    text-align: center;

    .title {
      text-transform: uppercase;
      margin-bottom: map-get($spacers, 4);
      line-height: $headings-line-height;
      @include media-breakpoint-up(lg) {
        font-size: $h1-font-size;
      }
      @include media-breakpoint-down(lg) {
        font-size: $h3-font-size;
      }
    }

    .content {
      @include media-breakpoint-up(lg) {
        font-size: $h2-font-size;
        line-height: $headings-line-height;
      }
      @include media-breakpoint-down(lg) {
      }
    }

    @include media-breakpoint-down(lg) {
      margin-bottom: map-get($spacers, 3);
    }
  }

  .copy-column {
    & > .d-flex {
      width: 100%;
    }
  }

  .double-column-a {
    :where(.pretitle) {
      text-transform: uppercase;
    }

    :where(.title) {
      text-transform: uppercase;
      font-weight: 500;
    }

    :where(.subtitle) {
      font-weight: 500;
    }

    .asset-column {
      img {
        width: 100%;
      }
    }

    @include media-breakpoint-up(lg) {
      // [class^='container'],
      // [class^='container'] .row {
      //   --bs-gutter-x: var(--bs-gutter-x, 0.75rem);
      // }

      .desktop-before-copy {
        .copy-column {
          order: 1;
        }
        .asset-column {
          order: 2;
        }
      }
      .desktop-before-asset {
        .copy-column {
          order: 2;
        }
        .asset-column {
          order: 1;
        }
      }

      :where(.pretitle) {
        font-size: $h5-font-size;
        line-height: $headings-line-height;
        margin-bottom: map-get($spacers, 0);
      }

      :where(.title) {
        font-size: $h1-font-size;
        line-height: $headings-line-height;
        margin-bottom: map-get($spacers, 3);
      }

      :where(.subtitle) {
        font-size: $h2-font-size;
        line-height: $headings-line-height;
        margin-bottom: map-get($spacers, 3);
      }

      :where(.content) {
        font-size: $h5-font-size;
        line-height: $headings-line-height;
      }

      .copy-column:has(.btn) {
        .content {
          margin-bottom: map-get($spacers, 4);
        }
      }
    }

    @include media-breakpoint-down(lg) {
      .mobile-before-copy {
        .copy-column {
          order: 1;
        }
        .asset-column {
          order: 2;
        }
      }
      .mobile-before-asset {
        .copy-column {
          order: 2;
        }
        .asset-column {
          order: 1;
        }
      }

      :where(.pretitle) {
        font-size: $small-font-size;
        margin-bottom: map-get($spacers, 0);
      }

      :where(.title) {
        font-size: $h3-font-size;
        line-height: $headings-line-height;
        margin-bottom: map-get($spacers, 2);
      }

      :where(.subtitle) {
        font-size: $h5-font-size;
        line-height: $headings-line-height;
        margin-bottom: map-get($spacers, 2);
      }

      :where(.content) {
        margin-bottom: map-get($spacers, 4);
      }
    }
  }
}
