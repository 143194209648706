@import '../css/shared';

.single-column-a {
  text-wrap: balance;

  .asset-container {
    grid-row: 1;
    grid-column: 1;

    img {
      width: 100%;
    }
  }

  .copy-container {
    grid-row: 1;
    grid-column: 1;
    pointer-events: none;

    .main-text-container {
      pointer-events: initial;

      .title {
        text-transform: uppercase;
        font-weight: 700;
        line-height: $headings-line-height;

        @include media-breakpoint-up(lg) {
          font-size: $h1-font-size;
        }

        @include media-breakpoint-down(lg) {
          font-size: $h3-font-size;
        }

        &:last-child {
          margin-bottom: 0 !important;
        }
      }

      .subtitle {
        margin: 0;
        @include media-breakpoint-up(lg) {
          font-size: $h5-font-size;
          line-height: $headings-line-height;
        }
        @include media-breakpoint-up(lg) {
        }

        &:last-child {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}
