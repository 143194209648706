@import 'css/shared';

.desktop-header-second-row {
  .menu {
    .menu-item {
      &.active {
        text-decoration: underline;
        text-underline-offset: 4px;
      }

      & > *:after {
        content: '';
        position: absolute;
        left: 0.5rem;
        bottom: 0.5rem;
        width: calc(100% - 1rem);
        height: 2px;
        background-color: transparent;
        transition: all 0.5s;
      }

      &.active > *,
      &:hover > * {
        cursor: pointer;

        & > *:after {
          background-color: #000000;
        }
      }

      svg {
        position: relative;
        top: -0.25rem;
      }
    }
  }

  .toggles,
  .logo {
    display: none;
    padding: map-get($spacers, 3) 0;
  }

  &.sticky {
    .toggles,
    .logo {
      display: block;
    }
  }

  .simple-search-form {
    svg {
      font-size: $h4-font-size;
    }
  }

  @include media-breakpoint-down(lg) {
  }
}
