@import '../css/shared';

.tabs-a {
  text-wrap: balance;

  .title {
    text-transform: uppercase;
    font-weight: 500;
    line-height: $headings-line-height;

    @include media-breakpoint-up(lg) {
      font-size: $h1-font-size;
    }

    @include media-breakpoint-down(lg) {
      font-size: $h3-font-size;
    }
  }

  .component-body {
    .tabs-content {
      .swiper-tab-nav-container {
        .swiper {
          padding: 10px 0;
          position: relative;
          &:before {
            content: '';
            width: 100%;
            height: 1px;
            background: $gray-300;
            bottom: 10px;
            left: 0;
            position: absolute;
          }
          .swiper-wrapper {
            @include media-breakpoint-up(lg) {
              justify-content: center;
            }
            .swiper-slide {
              width: auto;
              cursor: pointer;
              border-bottom: 1px solid transparent;
              padding: map-get($spacers, 2) 0;
              font-weight: 500;
            }

            &:not(:has(.swiper-slide-thumb-active)) .swiper-slide-active,
            .swiper-slide-thumb-active {
              position: relative;
              border-bottom: 1px solid $dark;

              &:before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 100%;
                margin: auto;
                width: 0px;
                height: 0px;
                border-style: solid;
                border-width: 10px 7px 0 7px;
                border-color: $dark transparent transparent transparent;
              }
            }
          }
        }
      }

      .swiper-tab-pane-container {
        .swiper {
          .swiper-slide {
            height: 0;
            visibility: hidden;
          }

          .swiper-slide-active {
            height: auto;
            visibility: visible;
          }
        }
      }
    }
  }
}
