@import '../css/shared';

.copy-b {
  text-wrap: pretty;

  .title {
    font-size: $h1-font-size;
    line-height: $headings-line-height;
    font-weight: 500;
    text-transform: uppercase;
  }

  @include media-breakpoint-up(sm) {
    .before-copy {
      .copy-column {
        order: 1;
      }
      .product-column {
        order: 2;
      }
    }
    .before-product {
      .copy-column {
        order: 2;
      }
      .product-column {
        order: 1;
      }
    }
  }
  @include media-breakpoint-down(sm) {
  }
}
