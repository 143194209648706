@import 'css/shared';

.cart-offcanvas {
  .cart-packs {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .cart-offcanvas-total {
    font-size: $h5-font-size;
  }

  .cart-offcanvas-to-free-shipping {
    background-color: $nude-300;
    padding: map-get($spacers, 2) !important;
    font-size: $extra-small-font-size;
    text-transform: uppercase;

    strong {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .Icon {
      font-size: 30px;
      margin-right: map-get($spacers, 2);
    }
  }
}
