@import '../css/shared';

.message-box-page {
  .dt,
  dd {
    word-wrap: break-word;
  }

  .text-container {
    background-color: rgba(255, 255, 255, 0.85);
  }

  @include media-breakpoint-up(md) {
    .message-box-content {
      min-height: 800px;
    }
  }
  @include media-breakpoint-down(md) {
  }
}
