@import '../css/shared';

.account-page {
    .menu-account {
        .list-group {

            .list-group-item {
                border-radius: 0;
                margin-bottom: spacer(1) !important;
                // border: 1px solid $gray-300 !important;
                gap: spacer(3) !important;
                font-weight: 700;
                @include media-breakpoint-up(xl) {
                    padding-left: spacer(4) !important;
                    padding-right: spacer(4) !important;
                }

                &.active {
                    background-color: $primary !important;
                    color: $white !important;
                    .Icon {
                        color: $white;
                    }
                }

                .Icon {
                    font-size: 20px !important;
                    color: $primary;
                }
            }
        }
    }

    .account-order-table {
        .mobile-data-title {
            .text-warning, .text-danger {
                color: $orange !important;
            }
        }
    }
}