@import 'css/shared';

.product-tile {
  // Media

  // &.out-of-stock {
  //   opacity: 0.5;
  //   transition: opacity 0.3s;

  //   &:hover {
  //     opacity: 1;
  //   }
  // }

  .product-skus {
    .slider-container {
      position: relative;

      .d-inline-block {
        display: block !important;
      }

      .swiper {
        .swiper-slide {
          width: auto;
          cursor: pointer;
        }
      }

      & > button {
        opacity: 1;
        width: 30px;
        background: $white;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 100;
        transition: all 0.3s;

        &.next {
          right: 0;
        }
        &.prev {
          left: 0;
        }

        &.swiper-button-disabled {
          visibility: hidden;
          opacity: 0;
        }
      }
    }
  }

  .reviews-summary-preview {
    margin-top: map-get($spacers, 1);
    font-size: $extra-small-font-size;
    line-height: 1;

    .rating-stars {
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  .product-media-container {
    position: relative;
    overflow: hidden;

    .image,
    .alternative-image {
      width: 100%;
      height: 100%;

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    .alternative-image {
      position: absolute;
      left: 0;
      top: 0;

      &:after,
      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 50%;
        height: 4px;
        bottom: 0;
        transition: all 0s;
        z-index: 100;
      }

      &:after {
        background-color: $nude;
        left: 0;
      }

      &:before {
        background-color: $nude-100;
        right: 0;
      }

      img {
        transition: all 0.3s;
        opacity: 0;
      }
    }
  }

  .product-tile-basic-info:hover .product-media-container {
    .alternative-image {
      &:after {
        background-color: $nude-100;
      }

      &:before {
        background-color: $nude;
      }

      img {
        opacity: 1;
      }
    }
  }

  // Best price

  .best-price {
    font-size: 10px;
    .Button {
      vertical-align: text-top;

      .Icon {
        font-size: 10px !important;
        width: 10px !important;
      }
    }
  }

  @include media-breakpoint-up(md) {
  }
  @include media-breakpoint-down(md) {
  }
}
