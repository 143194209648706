@import '../css/shared';

.registration-page, .whois-page {
    .services-icons {
        background-color: $white-nude;
        border-radius: 50%;
        text-align: center;
        padding: 18px;
        display: inline-block;
    }
    .service-icon {
        width: 40px;
        height: 40px;
    }
}