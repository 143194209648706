@import '../css/shared';

.accordion-a {
  text-wrap: balance;

  .title {
    text-transform: uppercase;
    font-weight: 500;
    line-height: $headings-line-height;

    @include media-breakpoint-up(lg) {
      font-size: $h1-font-size;
    }

    @include media-breakpoint-down(lg) {
      font-size: $h3-font-size;
    }
  }

  .component-body {
    .accordion-item {
      border-color: $gray-300 !important;

      @include media-breakpoint-down(lg) {
        width: calc(100% + var(--bs-gutter-x));
        margin-left: calc(var(--bs-gutter-x) * -0.5);
        .accordion-collapse {
          &.show {
            .swiper-tab-nav-container {
              position: sticky;
              top: 60px;
              z-index: 100;
            }
          }
        }

        .swiper-tab-pane-container {
          padding-left: calc(var(--bs-gutter-x) * 0.5);
          padding-right: calc(var(--bs-gutter-x) * 0.5);
        }
      }

      .accordion-header {
        button {
          font-weight: initial !important;
        }
      }

      .accordion-body {
        padding: 0;
        .swiper-tab-nav-container {
          padding-bottom: 20px;
          overflow: hidden;

          .swiper {
            overflow: visible;
            background: $white-nude;
            @include media-breakpoint-down(lg) {
              padding: 0 0.5rem;
            }

            .swiper-wrapper {
              @include media-breakpoint-up(lg) {
                justify-content: center;
              }

              .swiper-slide {
                width: auto;
                cursor: pointer;

                &.swiper-slide-disabled {
                  pointer-events: none;
                  .tab-nav {
                    color: $gray-300;
                  }
                }

                .tab-nav {
                  text-align: center;
                  color: $gray-500;
                  padding: map-get($spacers, 2);

                  .tab-nav-index {
                    @include media-breakpoint-up(lg) {
                      font-size: map-get($display-font-sizes, 1);
                    }
                    @include media-breakpoint-down(lg) {
                      font-size: map-get($display-font-sizes, 2);
                    }
                    font-family: 'Times New Roman';
                    line-height: 1;
                  }

                  .tab-nav-title {
                    font-size: $small-font-size;
                    text-transform: uppercase;
                  }
                }
              }
            }
          }

          &:not(:has(.swiper-slide-thumb-active)) .swiper-slide-active,
          .swiper-slide-thumb-active {
            .tab-nav {
              color: $primary !important;

              &:before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: calc(100% - 1px);
                margin: auto;
                width: 0px;
                height: 0px;
                border-style: solid;
                border-width: 20px 15px 0 15px;
                border-color: $white-nude transparent transparent transparent;
              }
            }
          }
        }

        .swiper-tab-pane-container {
          .swiper-slide {
            & > * {
              display: none;
            }

            &.swiper-slide-active > * {
              display: block;
            }
          }
        }

        .product-tile-extra-info {
          padding-bottom: 0 !important;
        }
      }
    }
  }

  .product-tile-extra-info {
    padding-bottom: 0 !important;
  }
}
