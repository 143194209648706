@import '../css/shared';

.products-slider-double-column-a {
  overflow: hidden;
  text-wrap: balance;

  .component-head {
    .pre-title {
      text-transform: uppercase;

      @include media-breakpoint-up(lg) {
        font-size: $h5-font-size;
        line-height: $headings-line-height;
      }

      @include media-breakpoint-down(lg) {
        font-size: $small-font-size;
      }
    }

    .title {
      text-transform: uppercase;
      font-weight: 500;
      line-height: $headings-line-height;

      @include media-breakpoint-up(lg) {
        font-size: $h1-font-size;
        margin-bottom: map-get($spacers, 4);
      }

      @include media-breakpoint-down(lg) {
        font-size: $h3-font-size;
        margin-bottom: map-get($spacers, 3);
      }
    }
  }

  .component-body {
    .asset-column-container {
      &.desktop-order-2 {
        @include media-breakpoint-up(lg) {
          order: 2;
        }
      }

      &.mobile-order-2 {
        @include media-breakpoint-down(lg) {
          order: 2;
        }
      }

      .asset-column > * {
        grid-row: 1;
        grid-column: 1;
      }
    }

    .products-column-container {
      :where(.pre-title) {
        font-size: $extra-small-font-size;
        text-transform: uppercase;
      }

      :where(.title) {
        font-size: $h4-font-size;
        line-height: $headings-line-height;
        font-weight: 500;
        text-transform: uppercase;
      }

      :where(.sub-title) {
        font-size: $extra-small-font-size;
        margin-top: map-get($spacers, 2);
      }

      .products-swiper-container {
        position: relative;

        .swiper-arrow {
          position: absolute;
          z-index: 100;
          top: 50%;

          &.swiper-arrow-left {
            left: 0;
          }

          &.swiper-arrow-right {
            right: 0;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .swiper {
      overflow: visible;
    }
  }

  .swiper-pagination {
    margin-bottom: map-get($spacers, 4);
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}
