@import '../css/shared';

.magazine-category-page {
  @include media-breakpoint-up(lg) {
  }
  @include media-breakpoint-down(lg) {
  }

  .magazine-navigation-section {
    overflow: hidden;

    .btn {
      text-transform: none;
      font-weight: 400;

      &.active {
        background: $light;
        color: $dark;
        text-decoration: underline;
      }
    }

    .swiper {
      overflow: visible;

      @include media-breakpoint-up(lg) {
        .swiper-wrapper {
          flex-wrap: wrap;
          justify-content: center;
          transform: none !important;
        }
      }

      .swiper-slide {
        width: auto;
      }
    }
  }
}
