@import '../css/shared';

.multi-column-a {
  text-wrap: balance;

  .title {
    text-transform: uppercase;
    font-weight: 500;
    line-height: $headings-line-height;

    @include media-breakpoint-up(lg) {
      font-size: $h1-font-size;
    }

    @include media-breakpoint-down(lg) {
      font-size: $h3-font-size;
    }
  }

  .tile-title {
    a {
      text-decoration: none;
    }
    line-height: $headings-line-height;

    @include media-breakpoint-up(lg) {
      font-size: $h4-font-size;
    }

    @include media-breakpoint-down(lg) {
      font-size: $h5-font-size;
    }
  }

  .swiper-pagination {
    margin: map-get($spacers, 3) 0;
  }

  .products-swiper-container {
    position: relative;

    .swiper-arrow {
      position: absolute;
      z-index: 100;
      top: 0;
      bottom: 0;
      margin: auto;

      &.swiper-arrow-left {
        left: 0;
      }

      &.swiper-arrow-right {
        right: 0;
      }
    }
  }
}
