@import '../css/shared';

.copy-a {
  text-wrap: pretty;

  .content-container {
    background-color: rgba(255, 255, 255, 0.7);
  }

  &.with-background {
    @include media-breakpoint-down(lg) {
      [class^='container'],
      [class^='container'] .row {
        --bs-gutter-x: 0rem;
      }
    }
  }

  .title {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: map-get($spacers, 4);
    line-height: $headings-line-height;
    @include media-breakpoint-up(lg) {
      font-size: $h1-font-size;
    }
    @include media-breakpoint-down(lg) {
      font-size: $h3-font-size;
    }
  }

  .content {
    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
      line-height: $headings-line-height;
    }
    @include media-breakpoint-down(lg) {
    }
  }
}
