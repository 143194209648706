@import '../css/shared';

.header-a {
  text-wrap: balance;

  .disclaimer {
    font-size: $extra-small-font-size;
  }

  .copy-container {
    position: relative;
    flex-direction: column;

    @include media-breakpoint-down(lg) {
      color: $dark !important;
    }
  }

  .main-container {
    @include media-breakpoint-down(lg) {
      background: $white !important;
    }
  }

  .title {
    font-weight: 500;
    text-transform: uppercase;
    line-height: $headings-line-height;

    @include media-breakpoint-up(lg) {
      font-size: $h1-font-size;
    }
    @include media-breakpoint-down(lg) {
      font-size: $h3-font-size;
    }
  }

  .subtitle {
    margin: 0;
    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
      line-height: $headings-line-height;
    }
    @include media-breakpoint-up(lg) {
    }
  }

  // Caso in cui è presente la Cta
  &:has(.btn) {
    .subtitle {
      margin-bottom: map-get($spacers, 4);
    }
  }

  // Caso in cui è presente l'Asset
  &:has(.asset-container) {
    .disclaimer {
      @include media-breakpoint-up(lg) {
        position: absolute;
        bottom: map-get($spacers, 5);
        width: 100%;
        left: 0;
      }
    }

    @include media-breakpoint-up(lg) {
      .main-container {
        display: grid;

        .asset-container {
          grid-row: 1;
          grid-column: 1;

          img {
            width: 100%;
          }
        }

        .copy-container {
          grid-row: 1;
          grid-column: 1;
        }
      }

      .main-container {
        position: relative;
      }
    }
    @include media-breakpoint-down(lg) {
      .copy-container {
        padding: map-get($spacers, 3) 0;
      }
    }
  }

  // Caso in cui NON è presente l'Asset
  &:not(&:has(.asset-container)) {
    @include media-breakpoint-up(lg) {
      .copy-container {
        padding: map-get($spacers, 5) 0;
      }
    }
    @include media-breakpoint-down(lg) {
      .copy-container {
        padding-bottom: map-get($spacers, 3);
      }
    }
  }
}
