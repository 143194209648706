@import '../css/shared';

.cart-page {
  .cart-packs,
  .cart-to-free-shipping,
  .cart-summary,
  .cart-payment {
    box-shadow: $box-shadow-sm;
  }

  .cart-packs {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .cart-summary-column {
    .cart-to-free-shipping {
      background-color: $white;

      .Icon {
        font-size: 30px;
        margin-right: map-get($spacers, 2);
      }

      .copy-container {
        padding: map-get($spacers, 2);
        margin: 0 !important;
      }

      .progress {
        border-radius: 0;
        background-color: $nude-300;
        height: 5px !important;

        div {
          background-color: $nude;
        }
      }
    }

    h5 {
      text-transform: uppercase;
      font-size: $h4-font-size;
      font-weight: 500;
    }

    .accordion-item {
      border-color: $border-color !important;
    }

    .generic-coupon-form {
      button[type='submit'] {
        border-color: $dark !important;
      }

      input:focus + button[type='submit'] {
        border-color: rgb(141, 139, 140) !important;
      }

      input.is-invalid + button[type='submit'] {
        border-color: $danger !important;
        color: $danger;
      }
    }

    .gift-card-form {
      label {
        margin-bottom: map-get($spacers, 3);
      }

      button {
        text-transform: none;
      }
    }

    .form-label {
      font-size: $font-size-base;
      font-weight: 700;
    }
  }
}
