@import '../css/shared';

.icons-c {
  @include media-breakpoint-up(lg) {
  }

  @include media-breakpoint-down(lg) {
  }

  .icons-column {
    > * {
      max-width: 120px;
    }

    p {
      text-transform: uppercase;
    }
  }

  .copy-column {
    height: 100%;

    .title {
      text-transform: uppercase;
    }

    .subTitle {
      font-size: $font-size-sm;
    }
  }
}
