@import '../css/shared';

.service-page {
    
    .aside-menu-title {
        text-transform: uppercase;
        font-weight: 500 !important;
        background-color: $white !important;
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
    }
  }