@import 'css/shared';

.reviews {
  h3,
  h4,
  h6 {
    font-weight: 700;
  }

  hr {
    background: $dark;
    opacity: 1;
  }

  table.rating-distribution svg,
  .rating svg {
    width: 12px;
  }

  .review {
    .name {
      margin-bottom: 0.5rem;
    }

    .rating {
      margin-bottom: 0.25rem;
    }

    .review-info {
      margin-bottom: 0.5rem;
      font-size: $font-size-base * 0.75;
      color: $gray-500;
    }
  }

  .summary {
    .title small {
      font-weight: 400;
    }

    table.rating-distribution {
      td {
        padding: 0.25rem 0;
      }

      td.rating-progress-bar {
        padding-left: 0.5rem;

        .progress {
          height: 0.5rem;
          background: $white;

          div {
            background: $dark;
          }

          small {
            display: none;
          }
        }
      }

      td.rating-percentage {
        display: none;
      }
    }

    .summary-footer {
      padding: 2rem;
      background: $white;
      border: 1px solid $gray-200;
      border-radius: $border-radius;
      text-align: center;
    }
  }

  @include media-breakpoint-up(lg) {
  }

  @include media-breakpoint-down(lg) {
  }
}

.review-form-modal {
  .modal-title {
    visibility: hidden;
  }

  .modal-body {
    padding: 0 1.5rem 1.5rem;
  }

  .review-modal-image {
    height: 100%;
    object-fit: cover;
  }

  h3 {
    font-size: $h2-font-size;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  .radio-rating-star {
    margin-right: 1rem !important;

    svg {
      cursor: pointer;
    }
  }

  .rating-form-group {
    span.form-label {
      margin-bottom: 1rem;
    }
  }
}
