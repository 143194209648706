@import '../css/shared';

.account-home-page {

  .btn {
    font-weight: 500 !important;
    text-transform: none;
  }

  .PageWithSubpageHeader {
    .title {
      font-size: $h3-font-size;
      text-transform: uppercase;
    }
  }

  .box-home-account, .help {
    h6 {
      text-transform: uppercase;
    }
    .Icon {
      font-size: 25px !important;
    }
    .box-title {
      font-weight: 500 !important;
    }
  }

}