@import 'css/shared';

.mobile-simple-search-offcanvas {
  height: auto;
  bottom: auto;

  .simple-search-form {

    svg {
        font-size: $h4-font-size;
    }

  }

}