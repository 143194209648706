@import 'css/shared';

.btn { text-transform: uppercase; }

.btn-link,
.btn-outline-link {
    padding: 0.68750rem 0;
}

.bg-dark .btn-link,
.bg-black .btn-link,
.bg-dark .btn-outline-link,
.bg-black .btn-outline-link {
    color: $light;
}

.btn-primary:hover,
.btn-primary:active,
.btn-light:hover,
.btn-light:active  {
    opacity: 0.8;
}

.btn-link:hover,
.btn-link:active {
    opacity: 0.7;
}

.btn-link:focus,
.btn-outline-link:focus {
    background-color: $light !important;
    box-shadow: 0 0 0 0.25rem $light;
}

.bg-dark .btn-link:focus,
.bg-black .btn-link:focus,
.bg-dark .btn-outline-link:focus,
.bg-black .btn-outline-link:focus {
    color: $light;
    background-color: $gray-400 !important;
    box-shadow: 0 0 0 0.25rem $gray-400;
}

.btn-primary:disabled { opacity: 0.5; }
.btn-light:disabled   { opacity: 0.6; }
.btn-link:disabled    { opacity: 0.3; }

.dropdown-toggle {
    font-weight: 400;
    text-transform: none;
}