@import '../css/shared';

.product-page {
  .product-variants-container .product-variants-container-form-group {
    & > div {
      margin-right: map-get($spacers, 2);

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .product-stickers {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 20;
    pointer-events: none;

    @include media-breakpoint-up(lg) {
      .sticker {
        font-size: $small-font-size;
      }
    }
    @include media-breakpoint-down(lg) {
      .sticker {
        font-size: $extra-small-font-size;
      }
    }
  }

  .product-gallery {
    .gallery-thumbnails,
    .gallery-slides {
      .swiper-slide {
        background: $gray-100;
      }
    }

    .gallery-thumbnails {
      .swiper {
        margin: -(map-get($spacers, 2));
        padding: map-get($spacers, 2);
      }

      .swiper-slide {
        border: 1px solid transparent;
        cursor: pointer;

        // &.border {
        // border-color: $light-blue !important;
        // box-shadow: 0 0 6px 1px rgba(var(--bs-light-blue-rgb), 0.4) ;
        // }
      }
    }

    .swiper-pagination {
      position: absolute !important;
      bottom: 1rem !important;
    }

    .slide-container {
      .fixed-aspect-ratio-box {
        padding: 0 !important;
        height: auto !important;

        & > div {
          position: relative !important;

          video {
            display: block;
            aspect-ratio: 4 / 5;
            background: $gray-100;
          }
        }
      }
    }

    .gallery-slides {
      width: 100%;
    }
  }

  .product-skus {
    .slider-container {
      position: relative;

      .d-inline-block {
        display: block !important;
      }

      .swiper {
        .swiper-slide {
          width: auto;
          cursor: pointer;
        }
      }

      & > button {
        opacity: 1;
        width: 30px;
        background: $white;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 100;
        transition: all 0.3s;

        &.next {
          right: 0;
        }
        &.prev {
          left: 0;
        }

        &.swiper-button-disabled {
          visibility: hidden;
          opacity: 0;
        }
      }
    }
  }

  .quantity-container {
    .btn-container {
      width: 40px;
      display: grid;
    }

    input {
      width: 100%;
    }

    input[type='number'] {
      -moz-appearance: textfield;
      appearance: textfield;
    }

    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      appearance: none;
    }
  }

  .product-third-section {
    overflow: hidden;
  }

  .reviews-summary-preview {
    .btn-review-first {
      background: transparent;
      text-transform: none;
      text-decoration: underline;
      color: $primary;
      font-weight: 400;
      font-size: $font-size-base * 0.875;
      padding: map-get($spacers, 1) 0;
      border: 0;
    }

    .reviews-count {
      font-weight: 400;
    }

    svg {
      width: 12px;
      height: 12px;
    }
  }

  @include media-breakpoint-up(lg) {
    .pasta-info-icon {
      max-width: 150px;
    }

    .product-gallery {
      .gallery-thumbnails {
        position: relative;
        width: 60px;

        .swiper {
          position: absolute;
          height: 100%;
          padding: 0;
          margin: 0;

          .swiper-slide {
            height: auto;
            width: 44px;
          }
        }
      }

      .gallery-slides {
        max-width: calc(100% - 60px);
      }

      .swiper-pagination {
        display: none;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .pasta-info-icon {
      max-width: 125px;
    }

    #product-accordion {
      .accordion-header button,
      .accordion-body {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .product-first-section {
      .container-xxl,
      .container-xxl .row {
        --bs-gutter-x: 0;
      }
    }

    .product-third-section {
      overflow: hidden;

      .swiper {
        width: 100%;
        overflow: visible;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .product-first-section {
      .AddToCartForm {
        .add-to-cart-button-container {
          display: grid;
          width: 100%;
        }
      }
    }
  }

  .product-promo-code {
    @include media-breakpoint-down(md) {
      .button-col {
        text-align: center;
      }
    }
  }

  .isenza-icon-container {
    @include media-breakpoint-up(md) {
      width: min-content;
    }
  }
}

.modal.modal-copy-coupon {
  .modal-header {
    justify-content: center;
    padding-bottom: 0 !important;

    .modal-title {
      font-size: $h3-font-size !important;
    }
  }

  .modal-body {
    text-align: center !important;
  }
}
