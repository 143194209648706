@import '../css/shared';

.asset-a {
  @include media-breakpoint-down(lg) {
    [class^='container'],
    [class^='container'] .row {
      --bs-gutter-x: 0rem;
    }
  }
}
