@import '../css/shared';

.copy-e {
  text-wrap: pretty;

  .title {
    text-transform: uppercase;
    line-height: $headings-line-height;
    font-weight: 500;

    @include media-breakpoint-up(md) {
      font-size: $h1-font-size;
    }
    @include media-breakpoint-down(md) {
      font-size: $h3-font-size;
    }
  }

  @include media-breakpoint-up(md) {
    .grid-layout {
      display: grid;
      grid-template-columns: 75% 25%;
      grid-template-rows: auto;
      column-gap: map-get($spacers, 5);
    }

    .copy {
      grid-column: 1 / 2;
      grid-row: 1 / 10;
    }

    .asset-a {
      grid-column: 2 / 2;
      grid-row: 1 / 2;
    }

    .asset-b {
      grid-column: 2 / 2;
      grid-row: 2 / 3;
    }
  }

  @include media-breakpoint-down(md) {
    [class^='container'] {
      --bs-gutter-x: 0rem;
    }

    .grid-layout {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    .copy {
      padding: map-get($spacers, 3);
    }
  }
}
