@import 'css/shared';

.offcanvas {
  .offcanvas-header {
    .offcanvas-title {
      text-transform: uppercase;
      font-weight: 500;
    }

    .btn {
      font-size: 20px;
    }
  }

  &.large-layout {
    width: 700px;
  }

  @include media-breakpoint-down(md) {
    .offcanvas-body {
      padding-left: map-get($spacers, 0);
      padding-right: map-get($spacers, 0);
    }
  }
}
