@import '../css/shared';

.products-slider-single-column-a {
  text-wrap: balance;

  @include media-breakpoint-down(lg) {
    overflow: hidden;

    .swiper {
      overflow: visible;
    }
  }

  .component-head {
    text-align: center;

    :where(.pre-title) {
      text-transform: uppercase;
    }

    :where(.title) {
      text-transform: uppercase;
      font-weight: 500;
      line-height: $headings-line-height;

      @include media-breakpoint-up(lg) {
        font-size: $h1-font-size;
      }

      @include media-breakpoint-down(lg) {
        font-size: $h3-font-size;
      }
    }
  }

  .products-swiper-container {
    position: relative;

    .swiper-arrow {
      position: absolute;
      z-index: 100;
      top: 0;
      bottom: 0;
      margin: auto;

      &.swiper-arrow-left {
        left: 0;
      }

      &.swiper-arrow-right {
        right: 0;
      }
    }
  }
}
