@import 'functions';
@import 'bootstrap';
@import 'mixins';
@import 'fonts';
@import 'b2x-components';
@import 'b2x-theme/b2x-theme';

// Decommentare questo stile per debuggare i CSS.
// * {
//   background-color: rgba(green, 0.05);
// }

body {
  overflow-y: scroll;
}

input::placeholder,
textarea::placeholder {
  font-size: $small-font-size;
}

input[type='checkbox']:checked {
  box-shadow: 0 0 0 3px $light inset;
}
input[type='radio']:checked {
  box-shadow: 0 0 0 3px $light inset;
}

.text-decoration-underline,
a {
  text-underline-offset: 4px;
}

.img-placeholder {
  background-color: $gray-100 !important;
  background-image: url('../images/no-image.svg');
  background-size: 30% auto;
  background-position: center;
  background-attachment: scroll;
  background-repeat: no-repeat;
}

.cart-campaign-single-offer-buyxgety-flattened-skus-picker {
  .sku {
    .image-container > div {
      width: 100%;
      height: auto !important;

      img {
        width: 100% !important;
      }
    }
  }
}

.btn-outline-nude {
  color: $dark;
}

#product-accordion {
  table {
    @extend .table;
  }
}
