@import '../shared';

.cart-sku {
  .product-name {
    font-weight: 700;
    font-size: $small-font-size;
    margin-bottom: map-get($spacers, 1) !important;
  }

  .product-sku-name {
    color: $gray-500;
    font-size: $small-font-size;
  }

  .cart-sku-price {
    font-size: $small-font-size;
  }

  .cart-sku-action {
    padding-top: map-get($spacers, 3) !important;

    .btn {
      text-transform: none;
      font-weight: 400;
    }
  }
}
