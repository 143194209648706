@import 'css/shared';

.mobile-search-filter-offcanvas {
  .offcanvas-header-wrapper {
    .offcanvas-header {
      border: 0 !important;
    }
    .offcanvas-title {
      font-weight: 500;
    }
  }
  .Drawers {
    .Button:not(.sorting-option-button) {
      border-radius: 0;
      border-color: $gray-200 !important;
    }
  }
}
