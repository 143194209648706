@import '../css/shared';

.downloader-a {
  background: $nude-100;

  .title {
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    @include media-breakpoint-up(lg) {
      font-size: $h1-font-size;
    }
    @include media-breakpoint-down(lg) {
      font-size: $h3-font-size;
    }
  }
}
