@import 'css/shared';

.footer {
  .accordion {
    @include media-breakpoint-up(lg) {
      .itemButtonClassName,
      .itemClassName {
        background-color: transparent;
      }

      .accordion-item {
        border: 0 !important;
      }
    }
  }

  .social-icons {
    .Icon {
      @include media-breakpoint-down(md) {
        font-size: 42px;
      }
    }
  }

  .accordion-item {
    border-radius: 0 !important;
    .accordion-header {
      @include media-breakpoint-up(lg) {
        margin-bottom: map-get($spacers, 2) !important;
      }
      .accordion-button {
        font-weight: 700;
        text-transform: uppercase;
        font-size: $font-size-base;
      }
    }
  }

  .footer-newsletter {
    border: 1px solid #f7e5df;
    background: #f7e5df;
    box-shadow: 0 0 10px #00000031;
    @include media-breakpoint-down(sm) {
      height: 23rem;
    }
  }
  .hr-mobile-footer {
    color: #F0DED8;
    height: 2px;
  }
}
