@import '../css/shared';

.multi-column-b {
  @include media-breakpoint-down(lg) {
    [class^='container'],
    [class^='container'] .row {
      --bs-gutter-x: 0rem;
    }
  }
  .title {
    font-weight: 500;
    text-transform: uppercase;
    line-height: $headings-line-height;
    @include media-breakpoint-up(lg) {
      order: 2;
      font-size: $h2-font-size;
    }
    @include media-breakpoint-down(lg) {
      text-align: center;
      font-size: $h3-font-size;
    }
  }

  .tile-inner-container {
    overflow: hidden;
    position: relative;

    .copy-container {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0);
      transition: all 0.3s;
      z-index: 5;
      overflow-y: auto;
      color: $light;
      padding: map-get($spacers, 3);
      visibility: hidden;
      opacity: 0;
    }

    .read-more {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      visibility: visible;
      opacity: 1;
      transition: all 0.3s;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        opacity: 0.6;
        width: 100%;
        height: 50%;
        background: rgb(0, 0, 0);
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
      }

      span {
        position: relative;
        z-index: 10;
        text-transform: uppercase;
        text-decoration: underline;
        font-size: $small-font-size;
        color: $light;
      }
    }

    &:hover {
      & .copy-container {
        background: rgba(0, 0, 0, 0.8);
        visibility: visible;
        opacity: 1;
      }

      & .read-more {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}
