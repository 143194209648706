@import '../css/shared';

.search-page {
  .header-a {
    @include media-breakpoint-up(lg) {
      background: $nude;
    }
  }

  // // Scrollbar customization - Start

  ::-webkit-scrollbar-track-piece:start {
    margin-top: 2.75rem;
  }

  ::-webkit-scrollbar-track-piece:end {
    margin-bottom: 3rem;
  }

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track-piece {
    background: $gray-200;
    border-radius: $border-radius;
  }

  ::-webkit-scrollbar-thumb {
    background: $gray-300;
    border-radius: $border-radius;
  }

  // // Scrollbar customization - End

  .search-filters-container {
    @include media-breakpoint-up(lg) {
      position: sticky;
      overflow-y: scroll;
      overflow-x: hidden;
      .Icon {
        font-size: 14px !important;
        width: 14px !important;
      }
      .form-check-label,
      .other-search-filter {
        font-size: $small-font-size;
      }
      .main-filter {
        font-weight: 700;
      }
    }

    .show-mobile-search-filter {
      color: $body-color;
      text-align: center;
      text-decoration: none;
      font-weight: 700;
      border-top: 1px solid $gray-200;
      border-bottom: 1px solid $gray-200;
      border-radius: 0;
      text-transform: none;
    }
  }

  .search-form-active-filter {
    .btn {
      text-transform: none;
    }
    .col {
      .btn {
        font-weight: normal;
      }
      &:last-child {
        .btn {
          font-weight: 500;
        }
      }
      &:only-child {
        .btn {
          font-weight: normal;
        }
      }
    }
  }

  .order-dropdown {
    button:after {
      display: none;
    }

    .dropdown-item.active,
    .dropdown-item:active {
      background-color: $white;
      font-weight: 700;
      color: $primary;
    }

    ul {
      top: 2px !important;
      padding: 0;
      border: 0;
      width: 100%;
    }
  }

  .list-links {
    overflow-x: auto;
    a {
      white-space: nowrap;
      &:focus {
        box-shadow: none;
      }
      &:hover,
      &:active,
      &.active {
        border-color: $primary;
        background-color: $nude-300;
        color: $primary;
      }
    }
  }

  .product-banner-col {
    @include media-breakpoint-up(xl) {
      width: 66.66%;
      order: 3;
    }

    @include media-breakpoint-down(xl) {
      width: 100%;
      order: 4;
    }

    @include media-breakpoint-down(lg) {
      width: 66.66%;
      order: 3;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      order: 4;
    }

    img {
      height: 100%;
      object-fit: cover;
    }

    .btn-mobile:hover {
      background: $primary !important;
    }
  }
}
