@import 'css/shared';

.mobile-navigation-offcanvas {

    .border-0 {
        
        .Button {
            border: 0 !important;
        }
        
    }

    @include media-breakpoint-down(lg) {}

}