@import 'css/shared';

.magazine-tile {
  .image {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    object-position: center center;
  }

  .title a {
    text-decoration: none;
    @include media-breakpoint-up(lg) {
      font-size: $h4-font-size;
      line-height: $headings-line-height;
    }
  }

  .content {
    @include media-breakpoint-down(lg) {
      font-size: $small-font-size;
    }
  }

  @include media-breakpoint-up(lg) {
    &.featured {
      .image {
        aspect-ratio: 2 / 1;
      }
    }
  }
}
