@import '../css/shared';

.instagram-a {
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: map-get($spacers, 3);

    text-transform: uppercase;
    font-weight: 500;
    line-height: $headings-line-height;
    @include media-breakpoint-up(lg) {
      font-size: $h1-font-size;
    }

    @include media-breakpoint-down(lg) {
      font-size: $h3-font-size;
    }
  }

  // 16px spacing
  .taggbox {
    @include media-breakpoint-only(xxl) {
      aspect-ratio: 16/3.7;
    }

    @include media-breakpoint-only(xl) {
      aspect-ratio: 16/5;
    }

    @include media-breakpoint-only(lg) {
      aspect-ratio: 16/5;
    }

    @include media-breakpoint-only(md) {
      aspect-ratio: 16/7.7;
    }

    @include media-breakpoint-only(sm) {
      aspect-ratio: 16/10.7;
    }

    @include media-breakpoint-only(xs) {
      aspect-ratio: 16/17.5;
    }
  }
}
