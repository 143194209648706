@import '../css/shared';

.copy-c {
  text-wrap: pretty;

  .main-title {
    @include media-breakpoint-up(lg) {
      font-size: $h1-font-size;
    }
    @include media-breakpoint-down(lg) {
      font-size: $h3-font-size;
    }
    line-height: $headings-line-height;
    font-weight: 500;
    text-transform: uppercase;
  }

  @include media-breakpoint-up(lg) {
    .title {
      font-size: $h3-font-size;
    }
  }

  @include media-breakpoint-down(lg) {
    .title {
      font-size: $h4-font-size;
    }

    .content {
      font-size: $small-font-size;
    }
  }
}
