@import '../css/shared';

.icons-b {
  .title {
    font-weight: 700;
    margin: 0;
    line-height: $headings-line-height;

    @include media-breakpoint-up(lg) {
      font-size: $h4-font-size;
    }

    @include media-breakpoint-down(lg) {
      font-size: $small-font-size;
    }
  }

  .content {
    @include media-breakpoint-up(lg) {
      font-size: $h4-font-size;
      line-height: $headings-line-height;
    }

    @include media-breakpoint-down(lg) {
      font-size: $small-font-size;
    }
  }
}
