@import '../css/shared';

.copy-d {
  text-wrap: pretty;

  .main-title {
    @include media-breakpoint-up(lg) {
      font-size: $h1-font-size;
    }
    @include media-breakpoint-down(lg) {
      font-size: $h3-font-size;
    }
    line-height: $headings-line-height;
    font-weight: 500;
    text-transform: uppercase;
  }

  .cta .btn {
    padding: 0;
    text-decoration: underline;
    font-weight: 400;
  }

  @include media-breakpoint-up(lg) {
    .title {
      font-size: $h3-font-size;
      order: 1;
    }
    .content {
      order: 2;
      margin-bottom: map-get($spacers, 4);
    }
    .cta {
      order: 3;
    }
    .asset {
      order: 4;
    }
  }

  @include media-breakpoint-down(lg) {
    .title {
      font-size: $h4-font-size;
      order: 2;
    }
    .content {
      font-size: $small-font-size;
      order: 3;
    }
    .cta {
      order: 4;
    }
    .asset {
      order: 1;
      margin-bottom: map-get($spacers, 1);
    }

    .equal-height-JlocK {
      height: auto !important;
    }
  }
}
